import { useCookies } from "vue3-cookies";
import { useAccountStore } from '@/stores/modules/account';

const { cookies } = useCookies();

export default async function middleware({ to, next }) {
  let loginRoutes = [
    'login',
    'password-reset',
    'code-confirmation',
    'new-password'
  ];

  let webRoutes = [
    '404',
    '500'
  ];

  const loginData = cookies.get("safarfy-login-data", {
    parseJSON: true,
  });

  if (webRoutes.includes(to.name)) {
    return next();
  } else {
    if (loginData) {
      const accountStore = useAccountStore();
      if (loginRoutes.includes(to.name)) {
        return next({ name: "dashboard", params: { lang: to.params.lang } });
      }
      if (to.meta.permissions && to.meta.permissions.length > 0 && !loginRoutes.includes(to.name)) {
        if (!accountStore.getAccountPermissions || accountStore.getAccountPermissions.length < 1) {
          await accountStore.loadAccountData();
        }
        if (accountStore.getAccountPermissions.length > 0 &&
          !accountStore.getAccountPermissions.some((value) =>
            to.meta.permissions.includes(value)
          )
        ) {
          return next({ name: "dashboard", params: { lang: to.params.lang } });
        }
      }
      return next();
    } else {
      if (!loginRoutes.includes(to.name))
        return next({ name: "login", params: { lang: to.params.lang } });
      return next();
    }
  }

}
