import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useInsurancestore = defineStore({
  id: "insurances",
  state: () => ({
    insurancesData: null,
  }),
  getters: {
    getInsurancesData: (state) => state.insurancesData,
  },
  actions: {
    async loadInsurancesData() {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(`/admin/services/insurances`)
        .then((response) => {
          appStore.loadingFinish();
          this.insurancesData = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateInsurancesData(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/services/insurances/update`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          this.insurancesData = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
