import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
// import { Tooltip } from "bootstrap";
import App from "./App.vue";

import  "./assets/_svgIcons";
import router from "./router";
import ElementPlus from "element-plus";
import ElementPlusLocaleAR from 'element-plus/dist/locale/ar.js'
import i18n from "@/core/plugins/i18n";
import VueMoment from "vue-moment-v3";
import { VsxIcon } from "vue-iconsax";
import permission from './directives/permissions';

//imports for app initialization
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import axiosInstance from "@/core/plugins/axios";
import toastification from "@/core/plugins/toastification";
import globalMixin from "@/core/plugins/globalMixin";
import "@/core/plugins/firebase";
import vSelect from 'vue-select';
import VueGoogleMaps from '@fawmi/vue-google-maps'

import VueLazyLoad from 'vue3-lazyload';
import lazyImage from "/media/svg/default_img.svg";
import defaultImage from "/media/svg/default_img.svg";
import { QuillEditor } from '@vueup/vue-quill';
import VueChatScroll from 'vue3-chat-scroll';
import { preventE } from './directives/prevent-e-directive';

import '@vueup/vue-quill/dist/vue-quill.snow.css';
import "vue-toastification/dist/index.css";
import "@/core/plugins/prismjs";

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import svgComponents from "./assets/_svgIcons";

const pinia = createPinia();

const app = createApp(App);

svgComponents(app);
app.use(pinia);
pinia.use(({ store }) => {
  store.$http = markRaw(app.config.globalProperties.$http);
  store.$notificationMessage = markRaw(
    app.config.globalProperties.$notificationMessage
  );
  store.$t = markRaw(app.config.globalProperties.$t);
});


toastification(app);
axiosInstance(app);
initApexCharts(app);
initInlineSvg(app);

app.mixin(globalMixin);
app.provide("http", app.config.globalProperties.$http);
app.provide("t", app.config.globalProperties.$t);

app.directive("permission", permission);
app.directive('prevent-e', preventE);

app
  .use(router)
  .use(ElementPlus, {
    // locale: ElementPlusLocaleAR,
  })
  .use(i18n)
  .use(VueMoment)
  .use(VueChatScroll)
  .use(VueViewer.default, {
    debug: true
  })
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyBqj2P0Cs3kD0WE9Ow27vsPCSfjvCK2p3g',
      language: 'ar',
      libraries: "places"
    },
  })
  .use(VueLazyLoad, {
    loading: lazyImage,
    error: defaultImage,
  });

app.component("VsxIcon", VsxIcon).component('QuillEditor', QuillEditor);

app.mount("#app");
