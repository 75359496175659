import auth from "./en/auth.json";
import validation from "./en/validation.json";
import users from "./en/users.json";

// contains all the translations for the app in Arabic
// export json object

export default {
  auth,
  validation,
  users
};
