import { permissions } from "@/enums/permissions.enum";

export default [
    {
        path: "chats",
        meta: {
            pageTitle: "sidebar.Chats",
            breadcrumbs: ["sidebar.Chats"],
        },
        children: [
            {
                path: "",
                name: "chats",
                component: () => import("@/views/chats/Index.vue"),
                meta: {
                    pageTitle: "sidebar.Chats",
                    breadcrumbs: [
                        {
                            title: "sidebar.Chats",
                            link: "chats",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readChats],
                    pagePermissions: {
                      create: permissions.sendMessage,
                      read: permissions.readChats,
                    }
                },
            },
        ],
    },
];
