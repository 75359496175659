import { permissions } from "@/enums/permissions.enum";

export default [
    {
        path: "users",
        meta: {
            pageTitle: "sidebar.Clients",
        },
        children: [
            {
                path: "clients",
                meta: {
                    pageTitle: "sidebar.Clients",
                    breadcrumbs: [
                        {
                            title: "sidebar.Users",
                            link: "users-clients",
                            disabled: false,
                        },
                        {
                            title: "sidebar.Clients",
                            link: "users-clients",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "users-clients",
                        component: () => import("@/views/users/clients/Index.vue"),
                        meta: {
                            pageTitle: "sidebar.Clients",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Users",
                                    link: "users-clients",
                                    disabled: false,
                                },
                                {
                                    title: "sidebar.Clients",
                                    link: "users-clients",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readClients],
                        },
                    },
                    {
                        path: ":id/details",
                        name: "users-clients-details",
                        component: () => import("@/views/users/clients/details/Index.vue"),
                        meta: {
                            pageTitle: "users.Client Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Users",
                                    link: "users-clients",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "users-clients",
                                    disabled: false,
                                },
                                {
                                    title: "users.Client Details",
                                    link: "users-clients-details-package-orders",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readClients],
                        },
                        children: [
                            {
                                path: "orders/package",
                                name: "users-clients-details-package-orders",
                                component: () => import("@/views/users/clients/details/PackageOrders.vue"),
                                meta: {
                                    pageTitle: "users.Client Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Users",
                                            link: "users-clients",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "users-clients",
                                            disabled: false,
                                        },
                                        {
                                            title: "users.Client Details",
                                            link: "users-clients-details-package-orders",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readClients],
                                },
                            },
                            {
                                path: "orders/insurance",
                                name: "users-clients-details-insurance-orders",
                                component: () => import("@/views/users/clients/details/InsuranceOrders.vue"),
                                meta: {
                                    pageTitle: "users.Client Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Users",
                                            link: "users-clients",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "users-clients",
                                            disabled: false,
                                        },
                                        {
                                            title: "users.Client Details",
                                            link: "users-clients-details-insurance-orders",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readClients],
                                },
                            },
                        ]
                    },
                ],
            },
            {
                path: "providers",
                meta: {
                    pageTitle: "sidebar.Service Providers",
                    breadcrumbs: [
                        {
                            title: "sidebar.Users",
                            link: "users-providers",
                            disabled: false,
                        },
                        {
                            title: "sidebar.Service Providers",
                            link: "users-providers",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "users-providers",
                        component: () => import("@/views/users/providers/Index.vue"),
                        meta: {
                            pageTitle: "sidebar.Service Providers",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Users",
                                    link: "users-providers",
                                    disabled: false,
                                },
                                {
                                    title: "sidebar.Service Providers",
                                    link: "users-providers",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readProviders, permissions.addProviders],
                            pagePermissions: {
                                create: permissions.addProviders,
                                edit: permissions.editProviders,
                                read: permissions.readProviders,
                                delete: permissions.deleteProviders,
                            }
                        },
                    },
                    {
                        path: "create",
                        name: "users-providers-create",
                        component: () => import("@/views/users/providers/create/Index.vue"),
                        meta: {
                            pageTitle: "users.Add a service provider",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Users",
                                    link: "users-providers",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "users-providers",
                                    disabled: false,
                                },
                                {
                                    title: "users.Add a service provider",
                                    link: "users-providers-create",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.addProviders],
                        },
                    },
                    {
                        path: ":id/edit",
                        name: "users-providers-edit",
                        component: () => import("@/views/users/providers/edit/Index.vue"),
                        meta: {
                            pageTitle: "users.Edit a service provider",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Users",
                                    link: "users-providers",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "users-providers",
                                    disabled: false,
                                },
                                {
                                    title: "users.Edit a service provider",
                                    link: "users-providers-edit",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.editProviders],
                        },
                    },
                ],
            },
        ],
    },
];
