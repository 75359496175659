import { permissions } from "@/enums/permissions.enum";

export default [
    {
        path: "orders",
        meta: {
            pageTitle: "sidebar.Package Orders",
        },
        children: [
            {
                path: "packages",
                meta: {
                    pageTitle: "sidebar.Package Orders",
                    breadcrumbs: [
                        {
                            title: "sidebar.Orders",
                            link: "orders-package",
                            disabled: false,
                        },
                        {
                            title: "common.Tourist Package Orders",
                            link: "orders-package",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readPackagesOrders],
                },
                children: [
                    {
                        path: "",
                        name: "orders-package",
                        component: () => import("@/views/orders/packages/Index.vue"),
                        meta: {
                            pageTitle: "sidebar.Package Orders",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Orders",
                                    link: "orders-package",
                                    disabled: false,
                                },
                                {
                                    title: "common.Tourist Package Orders",
                                    link: "orders-package",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readPackagesOrders],
                        },
                    },
                    {
                        path: ":id/details",
                        name: "orders-package-details",
                        component: () => import("@/views/orders/packages/details/Index.vue"),
                        meta: {
                            pageTitle: "orders.Order Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Orders",
                                    link: "orders-package",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "orders-package",
                                    disabled: false,
                                },
                                {
                                    title: "orders.Order Details",
                                    link: "orders-package-details-data",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readPackagesOrders],
                        },
                        children: [
                            {
                                path: "data",
                                name: "orders-package-details-data",
                                component: () => import("@/views/orders/packages/details/Data.vue"),
                                meta: {
                                    pageTitle: "orders.Order Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Orders",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "orders.Order Details",
                                            link: "orders-package-details-data",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readPackagesOrders],
                                },
                            },
                            {
                                path: "images",
                                name: "orders-package-details-images",
                                component: () => import("@/views/orders/packages/details/Images.vue"),
                                meta: {
                                    pageTitle: "orders.Order Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Orders",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "orders.Order Details",
                                            link: "orders-package-details-images",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readPackagesOrders],
                                },
                            },
                            {
                                path: "steps",
                                name: "orders-package-details-steps",
                                component: () => import("@/views/orders/packages/details/Steps.vue"),
                                meta: {
                                    pageTitle: "orders.Order Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Orders",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "orders.Order Details",
                                            link: "orders-package-details-steps",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readPackagesOrders],
                                },
                            },
                            {
                                path: "payment",
                                name: "orders-package-details-payment",
                                component: () => import("@/views/orders/packages/details/Payment.vue"),
                                meta: {
                                    pageTitle: "orders.Order Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Orders",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "orders.Order Details",
                                            link: "orders-package-details-payment",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readPackagesOrders],
                                },
                            },
                            {
                                path: "files",
                                name: "orders-package-details-files",
                                component: () => import("@/views/orders/packages/details/Files.vue"),
                                meta: {
                                    pageTitle: "orders.Order Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Orders",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "orders.Order Details",
                                            link: "orders-package-details-files",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readPackagesOrders],
                                },
                            },
                            {
                                path: "price-change-reason",
                                name: "orders-package-details-price-change-reason",
                                component: () => import("@/views/orders/packages/details/PriceChangeReason.vue"),
                                meta: {
                                    pageTitle: "orders.Order Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Orders",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "orders.Order Details",
                                            link: "orders-package-details-price-change-reason",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readPackagesOrders],
                                },
                            },
                            {
                                path: "cancel-reason",
                                name: "orders-package-details-cancel-reason",
                                component: () => import("@/views/orders/packages/details/CancelReason.vue"),
                                meta: {
                                    pageTitle: "orders.Order Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Orders",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "orders.Order Details",
                                            link: "orders-package-details-cancel-reason",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readPackagesOrders],
                                },
                            },
                            {
                                path: "review",
                                name: "orders-package-details-review",
                                component: () => import("@/views/orders/packages/details/Review.vue"),
                                meta: {
                                    pageTitle: "orders.Order Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Orders",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "orders-package",
                                            disabled: false,
                                        },
                                        {
                                            title: "orders.Order Details",
                                            link: "orders-package-details-review",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readPackagesOrders],
                                },
                            },
                        ]
                    },
                ],

            },
            // {
            //     path: "insurance",
            //     meta: {
            //         pageTitle: "sidebar.Insurance Orders",
            //         breadcrumbs: [
            //             {
            //                 title: "sidebar.Orders",
            //                 link: "orders-insurance",
            //                 disabled: false,
            //             },
            //             {
            //                 title: "sidebar.Insurance Orders",
            //                 link: "orders-insurance",
            //                 disabled: true,
            //             }
            //         ],
            //     },
            //     children: [
            //         {
            //             path: "",
            //             name: "orders-insurance",
            //             component: () => import("@/views/orders/insurance/Index.vue"),
            //             meta: {
            //                 pageTitle: "sidebar.Insurance Orders",
            //                 breadcrumbs: [
            //                     {
            //                         title: "sidebar.Orders",
            //                         link: "orders-insurance",
            //                         disabled: false,
            //                     },
            //                     {
            //                         title: "sidebar.Insurance Orders",
            //                         link: "orders-insurance",
            //                         disabled: true,
            //                     }
            //                 ],

            //             },
            //         },
            //         {
            //             path: ":id/details",
            //             name: "orders-insurance-details",
            //             component: () => import("@/views/orders/insurance/details/Index.vue"),
            //             meta: {
            //                 pageTitle: "orders.Order Details",
            //                 breadcrumbs: [
            //                     {
            //                         title: "sidebar.Orders",
            //                         link: "orders-insurance",
            //                         disabled: false,
            //                     },
            //                     {
            //                         title: "...",
            //                         link: "orders-insurance",
            //                         disabled: false,
            //                     },
            //                     {
            //                         title: "orders.Order Details",
            //                         link: "orders-insurance-details-data",
            //                         disabled: true,
            //                     }
            //                 ],
            //             },
            //             children: [
            //                 {
            //                     path: "data",
            //                     name: "orders-insurance-details-data",
            //                     component: () => import("@/views/orders/insurance/details/Data.vue"),
            //                     meta: {
            //                         pageTitle: "orders.Order Details",
            //                         breadcrumbs: [
            //                             {
            //                                 title: "sidebar.Orders",
            //                                 link: "orders-insurance",
            //                                 disabled: false,
            //                             },
            //                             {
            //                                 title: "...",
            //                                 link: "orders-insurance",
            //                                 disabled: false,
            //                             },
            //                             {
            //                                 title: "orders.Order Details",
            //                                 link: "orders-insurance-details-data",
            //                                 disabled: true,
            //                             }
            //                         ],
            //                     },
            //                 },
            //                 {
            //                     path: "payment",
            //                     name: "orders-insurance-details-payment",
            //                     component: () => import("@/views/orders/insurance/details/Payment.vue"),
            //                     meta: {
            //                         pageTitle: "orders.Order Details",
            //                         breadcrumbs: [
            //                             {
            //                                 title: "sidebar.Orders",
            //                                 link: "orders-insurance",
            //                                 disabled: false,
            //                             },
            //                             {
            //                                 title: "...",
            //                                 link: "orders-insurance",
            //                                 disabled: false,
            //                             },
            //                             {
            //                                 title: "orders.Order Details",
            //                                 link: "orders-insurance-details-payment",
            //                                 disabled: true,
            //                             }
            //                         ],
            //                     },
            //                 },
            //                 {
            //                     path: "files",
            //                     name: "orders-insurance-details-files",
            //                     component: () => import("@/views/orders/insurance/details/Files.vue"),
            //                     meta: {
            //                         pageTitle: "orders.Order Details",
            //                         breadcrumbs: [
            //                             {
            //                                 title: "sidebar.Orders",
            //                                 link: "orders-insurance",
            //                                 disabled: false,
            //                             },
            //                             {
            //                                 title: "...",
            //                                 link: "orders-insurance",
            //                                 disabled: false,
            //                             },
            //                             {
            //                                 title: "orders.Order Details",
            //                                 link: "orders-insurance-details-files",
            //                                 disabled: true,
            //                             }
            //                         ],
            //                     },
            //                 },
            //                 {
            //                     path: "cancel-reason",
            //                     name: "orders-insurance-details-cancel-reason",
            //                     component: () => import("@/views/orders/insurance/details/CancelReason.vue"),
            //                     meta: {
            //                         pageTitle: "orders.Order Details",
            //                         breadcrumbs: [
            //                             {
            //                                 title: "sidebar.Orders",
            //                                 link: "orders-insurance",
            //                                 disabled: false,
            //                             },
            //                             {
            //                                 title: "...",
            //                                 link: "orders-insurance",
            //                                 disabled: false,
            //                             },
            //                             {
            //                                 title: "orders.Order Details",
            //                                 link: "orders-insurance-details-cancel-reason",
            //                                 disabled: true,
            //                             }
            //                         ],
            //                     },
            //                 },
            //             ]
            //         },
            //     ],
            // },
        ],
    },
];
