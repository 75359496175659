import { permissions } from "@/enums/permissions.enum";

export default [
    {
        path: "advertisements",
        meta: {
            pageTitle: "sidebar.Advertisements",
            breadcrumbs: ["sidebar.Advertisements"],
        },
        children: [
            {
                path: "",
                name: "advertisements",
                component: () => import("@/views/advertisements/Index.vue"),
                meta: {
                    pageTitle: "sidebar.Advertisements",
                    breadcrumbs: [
                        {
                            title: "sidebar.Advertisements",
                            link: "advertisements",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readAds, permissions.addAds],
                    pagePermissions: {
                      create: permissions.addAds,
                      edit: permissions.editAds,
                      read: permissions.readAds,
                      delete: permissions.deleteAds,
                    }
                },
            },
            {
                path: "create",
                name: "advertisements-create",
                component: () => import("@/views/advertisements/create/Index.vue"),
                meta: {
                    pageTitle: "advertisements.Add a new ad",
                    breadcrumbs: [
                        {
                            title: "sidebar.Advertisements",
                            link: "advertisements",
                            disabled: false,
                        },
                        {
                            title: "advertisements.Add a new ad",
                            link: "advertisements-create",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.addAds],
                },
            },
            {
                path: ":id/edit",
                name: "advertisements-edit",
                component: () => import("@/views/advertisements/edit/Index.vue"),
                meta: {
                    pageTitle: "advertisements.Edit Ad Data",
                    breadcrumbs: [
                        {
                            title: "sidebar.Advertisements",
                            link: "advertisements",
                            disabled: false,
                        },
                        {
                            title: "advertisements.Edit Ad Data",
                            link: "advertisements-edit",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.editAds],
                },
            },
        ],
    },
];
