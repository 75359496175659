<template>
  <RouterView v-if="!isUserLoggedIn() || accountStore.getAccountData" />
</template>

<script lang="ts">
import { defineComponent, nextTick, onBeforeMount, onMounted } from "vue";
import { RouterView } from "vue-router";
import { useConfigStore } from "@/stores/config";
import { useThemeStore } from "@/stores/theme";
import { useBodyStore } from "@/stores/body";
import { themeConfigValue } from "@/layouts/default-layout/config/helper";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { isUserLoggedIn } from "@/core/helpers/auth";
import { useAccountStore } from "@/stores/modules/account";
import { useNotificationstore } from "@/stores";
import { useOrderPackagesStore } from "@/stores";
import { useOrderInsurancesStore } from "@/stores";

export default defineComponent({
  name: "app",
  components: {
    RouterView,
  },
  setup() {
    const configStore = useConfigStore();
    const themeStore = useThemeStore();
    const bodyStore = useBodyStore();
    const accountStore = useAccountStore();
    const notificationStore = useNotificationstore();
    const orderPackageStore = useOrderPackagesStore();
    const orderInsuranceStore = useOrderInsurancesStore();

    onBeforeMount(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/layouts/default-layout/config/DefaultLayoutConfig.ts)
       */
      configStore.overrideLayoutConfig();

      /**
       *  Sets a mode from configuration
       */
      themeStore.setThemeMode(themeConfigValue.value);
    });

    onMounted(() => {
      nextTick(() => {
        initializeComponents();
        bodyStore.removeBodyClassName("page-loading");
        if (isUserLoggedIn()) {
          accountStore.loadAccountData();
          notificationStore.loadDashboardNotifications(
            {
              page: 1,
              per_page: 4,
            },
            false
          );
        }
      });

      window.addEventListener("newNotification", (event) => {
        if (event.detail) {
          if (
            event.detail?.storage.data?.order_id &&
            orderPackageStore.getOrderPackageDetails &&
            orderPackageStore.getOrderPackageDetails?.id ==
              event.detail?.storage.data?.order_id
          ) {
            try {
              orderPackageStore.loadOrderPackageDetails(
                event.detail?.storage.data?.order_id
              );
            } catch (error) {}
          } else if (
            event.detail?.storage.data?.order_id &&
            orderInsuranceStore.getOrderInsuranceDetails &&
            orderInsuranceStore.getOrderInsuranceDetails?.id ==
              event.detail?.storage.data?.order_id
          ) {
            try {
              orderInsuranceStore.loadOrderInsuranceDetails(
                event.detail?.storage.data?.order_id
              );
            } catch (error) {}
          }

          if (event.detail?.storage.data?.type == "order-package") {
            orderPackageStore.loadOrderPackages({
              status: "",
              page: 1,
              per_page: 12,
            });
          } else if (event.detail?.storage.data?.type == "order-insurance") {
            orderInsuranceStore.loadOrderInsurances({
              status: "",
              page: 1,
              per_page: 12,
            });
          }

          notificationStore.loadDashboardNotifications(
            {
              page: 1,
              per_page: 4,
            },
            false
          );
        }
      });
    });

    return {
      accountStore,
      isUserLoggedIn,
    };
  },
});
</script>

<style lang="scss">
@import "apexcharts/dist/apexcharts.css";
@import "quill/dist/quill.snow.css";
@import "animate.css";

// @import "sweetalert2/dist/sweetalert2.css";
@import "nouislider/dist/nouislider.css";
// @import "@fortawesome/fontawesome-free/css/all.min.css";
// @import "socicon/css/socicon.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "dropzone/dist/dropzone.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";

#app {
  display: contents;
}
</style>