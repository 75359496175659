const permissions = {
    readHome: "read home",

    readPackagesOrders: "read packages orders",
    editPackagesOrdersData: "edit packages orders data",
    changePackagesOrdersStatus: "change packages orders status",
    packagesOrdersAddPaymentAmount: "packages orders add payment amount",

    readPackages: "read packages",
    addPackages: "add packages",
    editPackages: "edit packages",
    deletePackages: "delete packages",

    readClients: "read clients",
    editClients: "edit clients",

    readProviders: "read providers",
    addProviders: "add providers",
    editProviders: "edit providers",
    deleteProviders: "delete providers",


    readAds: "read ads",
    addAds: "add ads",
    editAds: "edit ads",
    deleteAds: "delete ads",

    readChats: "read chats",
    sendMessage: "send message",

    readFinancialClients: "read financial clients",

    readFinancialProviders: "read financial providers",
    addFinancialTransaction: "add financial transaction",
    
    readCompanyInfo: "read company info",

    readCountries: "read countries",
    addCountries: "add countries",
    editCountries: "edit countries",
    deleteCountries: "delete countries",

    readHotels: "read hotels",
    addHotels: "add hotels",
    editHotels: "edit hotels",
    deleteHotels: "delete hotels",

    readInclusions: "read inclusions",
    addInclusions: "add inclusions",
    editInclusions: "edit inclusions",
    deleteInclusions: "delete inclusions",

    readActivities: "read activities",
    addActivities: "add activities",
    editActivities: "edit activities",
    deleteActivities: "delete activities",

    readNotifications: "read notifications",
    addNotifications: "add notifications",
    editNotifications: "edit notifications",
    deleteNotifications: "delete notifications",

    readLogs: "read logs",

    readPrivacyPolicy: "read privacy policy",
    readTermsConditions: "read terms conditions",

    readSysUsers: "read sys users",
    addSysUsers: "add sys users",
    deleteSysUsers: "delete sys users",
    editSysUsers: "edit sys users",
    
    readRoles: "read roles",
    addRoles: "add roles",
    deleteRoles: "delete roles",
    editRoles: "edit roles",
};

export { permissions };