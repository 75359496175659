import { permissions } from "@/enums/permissions.enum";

export default [
    {
        path: "packages",
        meta: {
            pageTitle: "sidebar.Tourist Packages",
            breadcrumbs: [
                {
                    title: "sidebar.Tourist Packages",
                    link: "packages",
                    disabled: true,
                }
            ],
        },
        children: [
            {
                path: "",
                name: "packages",
                component: () => import("@/views/packages/Index.vue"),
                meta: {
                    pageTitle: "sidebar.Tourist Packages",
                    breadcrumbs: [
                        {
                            title: "sidebar.Tourist Packages",
                            link: "packages",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readPackages, permissions.addPackages],
                    pagePermissions: {
                        create: permissions.addPackages,
                        edit: permissions.editPackages,
                        read: permissions.readPackages,
                        delete: permissions.deletePackages,
                    }
                },
            },
            {
                path: "create",
                name: "packages-create",
                component: () => import("@/views/packages/create/Index.vue"),
                meta: {
                    pageTitle: "packages.Add a new tourist package",
                    breadcrumbs: [
                        {
                            title: "sidebar.Tourist Packages",
                            link: "packages",
                            disabled: false,
                        },
                        {
                            title: "packages.Add a new package",
                            link: "packages-create",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.addPackages],
                },
            },
            {
                path: ":id/edit",
                name: "packages-edit",
                component: () => import("@/views/packages/edit/Index.vue"),
                meta: {
                    pageTitle: "packages.Edit Package Data",
                    breadcrumbs: [
                        {
                            title: "sidebar.Tourist Packages",
                            link: "packages",
                            disabled: false,
                        },
                        {
                            title: "packages.Edit Package Data",
                            link: "packages-edit",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.editPackages],
                },
            },
            {
                path: ":id/details",
                name: "packages-details",
                component: () => import("@/views/packages/details/Index.vue"),
                meta: {
                    pageTitle: "packages.Package Details",
                    breadcrumbs: [
                        {
                            title: "sidebar.Tourist Packages",
                            link: "packages",
                            disabled: false,
                        },
                        {
                            title: "common.View Details",
                            link: "packages-details-about",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readPackages],
                },
                children: [
                    {
                        path: "about",
                        name: "packages-details-about",
                        component: () => import("@/views/packages/details/About.vue"),
                        meta: {
                            pageTitle: "packages.Package Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Tourist Packages",
                                    link: "packages",
                                    disabled: false,
                                },
                                {
                                    title: "common.View Details",
                                    link: "packages-details-about",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readPackages],
                        },
                    },
                    {
                        path: "cities",
                        name: "packages-details-cities",
                        component: () => import("@/views/packages/details/Cities.vue"),
                        meta: {
                            pageTitle: "packages.Package Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Tourist Packages",
                                    link: "packages",
                                    disabled: false,
                                },
                                {
                                    title: "common.View Details",
                                    link: "packages-details-cities",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readPackages],
                        },
                    },
                    {
                        path: "images",
                        name: "packages-details-images",
                        component: () => import("@/views/packages/details/Images.vue"),
                        meta: {
                            pageTitle: "packages.Package Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Tourist Packages",
                                    link: "packages",
                                    disabled: false,
                                },
                                {
                                    title: "common.View Details",
                                    link: "packages-details-images",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readPackages],
                        },
                    },
                    {
                        path: "hotels",
                        name: "packages-details-hotels",
                        component: () => import("@/views/packages/details/Hotels.vue"),
                        meta: {
                            pageTitle: "packages.Package Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Tourist Packages",
                                    link: "packages",
                                    disabled: false,
                                },
                                {
                                    title: "common.View Details",
                                    link: "packages-details-hotels",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readPackages],
                        },
                    },
                    {
                        path: "steps",
                        name: "packages-details-steps",
                        component: () => import("@/views/packages/details/Steps.vue"),
                        meta: {
                            pageTitle: "packages.Package Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Tourist Packages",
                                    link: "packages",
                                    disabled: false,
                                },
                                {
                                    title: "common.View Details",
                                    link: "packages-details-steps",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readPackages],
                        },
                    },
                    {
                        path: "informations",
                        name: "packages-details-informations",
                        component: () => import("@/views/packages/details/Informations.vue"),
                        meta: {
                            pageTitle: "packages.Package Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Tourist Packages",
                                    link: "packages",
                                    disabled: false,
                                },
                                {
                                    title: "common.View Details",
                                    link: "packages-details-informations",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readPackages],
                        },
                    },
                ]
            },
        ],
    },
];
