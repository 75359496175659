import { permissions } from "@/enums/permissions.enum";

export default [
    {
        path: "settings",
        meta: {
            pageTitle: "sidebar.Settings",
            breadcrumbs: [
                {
                    title: "sidebar.Settings",
                    link: "settings",
                    disabled: true,
                }
            ],
        },
        children: [
            {
                path: "",
                name: "settings",
                component: () => import("@/views/settings/Index.vue"),
                meta: {
                    pageTitle: "sidebar.Settings",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readCompanyInfo, permissions.readCountries, permissions.readSysUsers,
                    permissions.readHotels, permissions.readInclusions, permissions.readActivities, permissions.readNotifications, permissions.readLogs, permissions.readPrivacyPolicy, permissions.readTermsConditions],
                },
            },
            {
                path: "company-information",
                name: "settings-company-information",
                component: () => import("@/views/settings/companyInformation/Index.vue"),
                meta: {
                    pageTitle: "settings.Company Information",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Company Information",
                            link: "settings-company-information",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readCompanyInfo],
                    pagePermissions: {
                        read: permissions.readCompanyInfo,
                    }
                },
            },
            {
                path: "privacy-policy",
                name: "settings-privacy-policy",
                component: () => import("@/views/settings/privacyPolicy/Index.vue"),
                meta: {
                    pageTitle: "settings.Privacy policy",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Privacy policy",
                            link: "settings-privacy-policy",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readPrivacyPolicy],
                    pagePermissions: {
                        read: permissions.readPrivacyPolicy,
                    }
                },
            },
            {
                path: "terms-conditions",
                name: "settings-terms-conditions",
                component: () => import("@/views/settings/termsConditions/Index.vue"),
                meta: {
                    pageTitle: "settings.Terms & Conditions",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Terms & Conditions",
                            link: "settings-terms-conditions",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readTermsConditions],
                    pagePermissions: {
                        read: permissions.readTermsConditions,
                    }
                },
            },
            {
                path: "travel-insurance",
                name: "settings-travel-insurance",
                component: () => import("@/views/settings/travelInsurance/Index.vue"),
                meta: {
                    pageTitle: "settings.Travel Insurance",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Travel Insurance",
                            link: "settings-travel-insurance",
                            disabled: true,
                        }
                    ],
                    permissions: [],
                },
            },

            {
                path: "countries",
                meta: {
                    pageTitle: "settings.Countries & Cities",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Countries & Cities",
                            link: "settings-countries",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "settings-countries",
                        component: () => import("@/views/settings/countries/Index.vue"),
                        meta: {
                            pageTitle: "settings.Countries & Cities",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Countries & Cities",
                                    link: "settings-countries",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readCountries, permissions.addCountries],
                            pagePermissions: {
                                create: permissions.addCountries,
                                edit: permissions.editCountries,
                                read: permissions.readCountries,
                                delete: permissions.deleteCountries,
                            }
                        },
                    },
                    {
                        path: "create",
                        name: "settings-countries-create",
                        component: () => import("@/views/settings/countries/create/Index.vue"),
                        meta: {
                            pageTitle: "settings.Add a country",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-countries",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Add a country",
                                    link: "settings-countries-create",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.addCountries],
                        },
                    },
                    {
                        path: ":id/details",
                        name: "settings-countries-details",
                        component: () => import("@/views/settings/countries/details/Index.vue"),
                        meta: {
                            pageTitle: "settings.Countries & Cities",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-countries",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Country Details",
                                    link: "settings-countries-details",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readCountries],
                        },
                        children: [
                            {
                                path: "data",
                                name: "settings-countries-details-data",
                                component: () => import("@/views/settings/countries/details/Data.vue"),
                                meta: {
                                    pageTitle: "settings.Countries & Cities",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Settings",
                                            link: "settings",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "settings-countries",
                                            disabled: false,
                                        },
                                        {
                                            title: "settings.Country Details",
                                            link: "settings-countries-details-data",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readCountries],
                                },
                            },
                            {
                                path: "cities",
                                name: "settings-countries-details-cities",
                                component: () => import("@/views/settings/countries/details/Cities.vue"),
                                meta: {
                                    pageTitle: "settings.Countries & Cities",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Settings",
                                            link: "settings",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "settings-countries",
                                            disabled: false,
                                        },
                                        {
                                            title: "settings.Country Details",
                                            link: "settings-countries-details-cities",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readCountries],
                                },
                            },

                        ]
                    },
                    {
                        path: ":id/edit",
                        name: "settings-countries-edit",
                        component: () => import("@/views/settings/countries/edit/Index.vue"),
                        meta: {
                            pageTitle: "settings.Edit of country data",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-countries",
                                    disabled: false,
                                },
                                {
                                    title: "common.Edit Data",
                                    link: "settings-countries-edit",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.editCountries],
                        },
                    },
                ],
            },
            {
                path: "admins",
                meta: {
                    pageTitle: "settings.Administrator accounts",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Administrator accounts",
                            link: "settings-admins",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "settings-admins",
                        component: () => import("@/views/settings/admins/Index.vue"),
                        meta: {
                            pageTitle: "settings.Administrator accounts",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Administrator accounts",
                                    link: "settings-admins",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readSysUsers, permissions.addSysUsers],
                            pagePermissions: {
                                create: permissions.addSysUsers,
                                edit: permissions.editSysUsers,
                                read: permissions.readSysUsers,
                                delete: permissions.deleteSysUsers,
                            }
                        },
                    },
                    {
                        path: "create",
                        name: "settings-admins-create",
                        component: () => import("@/views/settings/admins/create/Index.vue"),
                        meta: {
                            pageTitle: "settings.Add a new admin",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-admins",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Add a new admin",
                                    link: "settings-admins-create",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.addSysUsers],
                        },
                    },
                    {
                        path: ":id/edit",
                        name: "settings-admins-edit",
                        component: () => import("@/views/settings/admins/edit/Index.vue"),
                        meta: {
                            pageTitle: "common.Edit admin data",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-admins",
                                    disabled: false,
                                },
                                {
                                    title: "common.Edit Data",
                                    link: "settings-admins-edit",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.editSysUsers],
                        },
                    },
                ],
            },

            {
                path: "permissions",
                meta: {
                    pageTitle: "settings.Permissions Management",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "...",
                            link: "settings-permissions",
                            disabled: false,
                        },
                        {
                            title: "settings.Permissions Management",
                            link: "settings-permissions",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "settings-permissions",
                        component: () => import("@/views/settings/permissions/Index.vue"),
                        meta: {
                            pageTitle: "settings.Permissions Management",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-permissions",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Permissions Management",
                                    link: "settings-permissions",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readRoles, permissions.addRoles],
                            pagePermissions: {
                                create: permissions.addRoles,
                                edit: permissions.editRoles,
                                read: permissions.readRoles,
                                delete: permissions.deleteRoles,
                            }
                        },
                    },
                    {
                        path: "create",
                        name: "settings-permissions-create",
                        component: () => import("@/views/settings/permissions/create/Index.vue"),
                        meta: {
                            pageTitle: "settings.Add a new permission",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-permissions",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Add a new permission",
                                    link: "settings-permissions-create",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.addRoles],
                        },
                    },
                    {
                        path: ":id/edit",
                        name: "settings-permissions-edit",
                        component: () => import("@/views/settings/permissions/edit/Index.vue"),
                        meta: {
                            pageTitle: "common.Edit permission data",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-permissions",
                                    disabled: false,
                                },
                                {
                                    title: "common.Edit Data",
                                    link: "settings-permissions-edit",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.editRoles],
                        },
                    },
                ],
            },

            {
                path: "hotels",
                meta: {
                    pageTitle: "settings.Hotels",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Hotels",
                            link: "settings-hotels",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readHotels],
                    pagePermissions: {
                        read: permissions.readHotels,
                    }
                },
                children: [
                    {
                        path: "",
                        name: "settings-hotels",
                        component: () => import("@/views/settings/hotels/Index.vue"),
                        meta: {
                            pageTitle: "settings.Hotels",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Hotels",
                                    link: "settings-hotels",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readHotels, permissions.addHotels],
                            pagePermissions: {
                                read: permissions.readHotels,
                                create: permissions.addHotels,
                                edit: permissions.editHotels,
                                delete: permissions.deleteHotels,
                            }
                        },
                    },
                    {
                        path: "create",
                        name: "settings-hotels-create",
                        component: () => import("@/views/settings/hotels/create/Index.vue"),
                        meta: {
                            pageTitle: "common.Add a new hotel",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-hotels",
                                    disabled: false,
                                },
                                {
                                    title: "common.Add a hotel",
                                    link: "settings-hotels-create",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.addHotels],
                        },
                    },
                    {
                        path: ":id/edit",
                        name: "settings-hotels-edit",
                        component: () => import("@/views/settings/hotels/edit/Index.vue"),
                        meta: {
                            pageTitle: "common.Edit a hotel data",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-hotels",
                                    disabled: false,
                                },
                                {
                                    title: "common.Edit Data",
                                    link: "settings-hotels-edit",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.editHotels],
                        },
                    },

                    {
                        path: ":id/details",
                        name: "settings-hotels-details",
                        component: () => import("@/views/settings/hotels/details/Index.vue"),
                        meta: {
                            pageTitle: "common.Hotel Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-hotels",
                                    disabled: false,
                                },
                                {
                                    title: "common.Details",
                                    link: "settings-hotels-details-data",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readHotels],
                        },
                        children: [
                            {
                                path: "data",
                                name: "settings-hotels-details-data",
                                component: () => import("@/views/settings/hotels/details/Data.vue"),
                                meta: {
                                    pageTitle: "common.Hotel Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Settings",
                                            link: "settings",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "settings-hotels",
                                            disabled: false,
                                        },
                                        {
                                            title: "common.Details",
                                            link: "settings-hotels-details-data",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readHotels],
                                },
                            },
                            {
                                path: "location",
                                name: "settings-hotels-details-location",
                                component: () => import("@/views/settings/hotels/details/Location.vue"),
                                meta: {
                                    pageTitle: "common.Hotel Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Settings",
                                            link: "settings",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "settings-hotels",
                                            disabled: false,
                                        },
                                        {
                                            title: "common.Details",
                                            link: "settings-hotels-details-location",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readHotels],
                                },
                            },
                            {
                                path: "rooms",
                                name: "settings-hotels-details-rooms",
                                component: () => import("@/views/settings/hotels/details/Rooms.vue"),
                                meta: {
                                    pageTitle: "common.Hotel Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Settings",
                                            link: "settings",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "settings-hotels",
                                            disabled: false,
                                        },
                                        {
                                            title: "common.Details",
                                            link: "settings-hotels-details-rooms",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readHotels],
                                },
                            },
                            {
                                path: "images",
                                name: "settings-hotels-details-images",
                                component: () => import("@/views/settings/hotels/details/Images.vue"),
                                meta: {
                                    pageTitle: "common.Hotel Details",
                                    breadcrumbs: [
                                        {
                                            title: "sidebar.Settings",
                                            link: "settings",
                                            disabled: false,
                                        },
                                        {
                                            title: "...",
                                            link: "settings-hotels",
                                            disabled: false,
                                        },
                                        {
                                            title: "common.Details",
                                            link: "settings-hotels-details-images",
                                            disabled: true,
                                        }
                                    ],
                                    permissions: [permissions.readHotels],
                                },
                            },
                        ]
                    },
                ],
            },


            {
                path: "inclusions",
                meta: {
                    pageTitle: "settings.Package Inclusions",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Inclusions",
                            link: "settings-inclusions",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "settings-inclusions",
                        component: () => import("@/views/settings/inclusions/Index.vue"),
                        meta: {
                            pageTitle: "settings.Package Inclusions",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Inclusions",
                                    link: "settings-inclusions",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readInclusions, permissions.addInclusions],
                            pagePermissions: {
                                read: permissions.readInclusions,
                                create: permissions.addInclusions,
                                edit: permissions.editInclusions,
                                delete: permissions.deleteInclusions,
                            }
                        },
                    },
                ],
            },


            {
                path: "activities",
                meta: {
                    pageTitle: "settings.Types of activities",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Activities",
                            link: "settings-activities",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "settings-activities",
                        component: () => import("@/views/settings/activities/Index.vue"),
                        meta: {
                            pageTitle: "settings.Types of activities",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Activities",
                                    link: "settings-activities",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readActivities, permissions.addActivities],
                            pagePermissions: {
                                read: permissions.readActivities,
                                create: permissions.addActivities,
                                edit: permissions.editActivities,
                                delete: permissions.deleteActivities,
                            }
                        },
                    },
                    {
                        path: "create",
                        name: "settings-activities-create",
                        component: () => import("@/views/settings/activities/create/Index.vue"),
                        meta: {
                            pageTitle: "common.Add a new activity",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "common.Add a type",
                                    link: "settings-activities-create",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.addActivities],
                        },
                    },
                ],
            },


            {
                path: "notifications",
                meta: {
                    pageTitle: "settings.Send notifications",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Notifications",
                            link: "settings-notifications",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "settings-notifications",
                        component: () => import("@/views/settings/notifications/Index.vue"),
                        meta: {
                            pageTitle: "settings.Send notifications",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Notifications",
                                    link: "settings-notifications",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readNotifications, permissions.addNotifications],
                            pagePermissions: {
                                read: permissions.readNotifications,
                                create: permissions.addNotifications,
                                edit: permissions.editNotifications,
                                delete: permissions.deleteNotifications,
                            }
                        },
                    },
                    {
                        path: "create",
                        name: "settings-notifications-create",
                        component: () => import("@/views/settings/notifications/create/Index.vue"),
                        meta: {
                            pageTitle: "settings.Add a new notification",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-notifications",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Add a new notification",
                                    link: "settings-notifications-create",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.addNotifications],
                        },
                    },
                    {
                        path: ":id/details",
                        name: "settings-notifications-details",
                        component: () => import("@/views/settings/notifications/details/Index.vue"),
                        meta: {
                            pageTitle: "settings.Notice Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-notifications",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Notice Details",
                                    link: "settings-notifications-details",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readNotifications],
                        },
                    },
                    {
                        path: ":id/edit",
                        name: "settings-notifications-edit",
                        component: () => import("@/views/settings/notifications/edit/Index.vue"),
                        meta: {
                            pageTitle: "settings.Edit Notification Data",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Settings",
                                    link: "settings",
                                    disabled: false,
                                },
                                {
                                    title: "...",
                                    link: "settings-notifications",
                                    disabled: false,
                                },
                                {
                                    title: "settings.Edit Notification",
                                    link: "settings-notifications-edit",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.editNotifications],
                        },
                    },
                ],
            },

            {
                path: "log",
                name: "settings-log",
                component: () => import("@/views/settings/log/Index.vue"),
                meta: {
                    pageTitle: "settings.Activity log",
                    breadcrumbs: [
                        {
                            title: "sidebar.Settings",
                            link: "settings",
                            disabled: false,
                        },
                        {
                            title: "settings.Activity log",
                            link: "settings-log",
                            disabled: true,
                        }
                    ],
                    permissions: [permissions.readLogs],
                },
            },

        ],
    },
];
