import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useFinancialProviderStore = defineStore({
  id: "financialProvider",
  state: () => ({
    financialProviders: [],
    totalFinancialProviders: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    financialProvidersStatsistics: [],
    financialProvider: null,
    financialProvidersTransactions: [],
    totalFinancialProvidersTransactions: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  }),
  getters: {
    getFinancialProviders: (state) => state.financialProviders,
    getTotalFinancialProviders: (state) => state.totalFinancialProviders,
    getFinancialProvidersStatistics: (state) => state.financialProvidersStatsistics,
    getFinancialProvider: (state) => state.financialProvider,
    getFinancialProvidersTransactions: (state) => state.financialProvidersTransactions,
    getTotalFinancialProvidersTransactions: (state) => state.totalFinancialProvidersTransactions,
  },
  actions: {
    async loadFinancialProviders(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/financials/providers`,
          {
            params: data,
          }
        )
        .then((response) => {
          appStore.loadingFinish();
          this.financialProviders = response.data.data.list.data;
          this.totalFinancialProviders.currentPage = response.data.data.list.current_page;
          this.totalFinancialProviders.totalItems = response.data.data.list.total;
          this.totalFinancialProviders.totalPages = response.data.data.list.last_page;
          this.financialProvidersStatsistics = response.data.data.statistics;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadFinancialProviderDetails(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/financials/providers/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.financialProvider = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadFinancialProviderTransactions(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/financials/providers/detail/${data.id}/transactions`,
          {
            params: data,
          }
        )
        .then((response) => {
          appStore.loadingFinish();
          this.financialProvidersTransactions = response.data.data.data;
          this.totalFinancialProvidersTransactions.currentPage = response.data.data.current_page;
          this.totalFinancialProvidersTransactions.totalItems = response.data.data.total;
          this.totalFinancialProvidersTransactions.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async addFinancialTransaction(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/financials/providers/transaction/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          this.financialProvider = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

  },
});
