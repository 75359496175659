import auth from "./ar/auth.json";
import validation from "./ar/validation.json";
import users from "./ar/users.json";
import sidebar from "./ar/sidebar.json";
import common from "./ar/common.json";
import settings from "./ar/settings.json";

// contains all the translations for the app in Arabic
// export json object

export default {
  auth,
  validation,
  settings,
  common,
  sidebar,
  users
};
