import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useActivitystore = defineStore({
  id: "activities",
  state: () => ({
    activities: [],
    totalActivities: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    allActivities: [],
    activityDetails: null,
  }),
  getters: {
    getActivities: (state) => state.activities,
    getTotalActivities: (state) => state.totalActivities,
    getAllActivities: (state) => state.allActivities,
    getActivityDetails: (state) => state.activityDetails,
  },
  actions: {
    async loadActivities(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/activities?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.activities = response.data.data.data;
          this.totalActivities.currentPage = response.data.data.current_page;
          this.totalActivities.totalItems = response.data.data.total;
          this.totalActivities.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadAllActivities(fullLoad = false) {
      const appStore = useAppsStore();
      appStore.shimmerLoadingStart();
      if (fullLoad) {
        appStore.loadingStart();
      }
      return await this.$http
        .get(
          `/admin/activities/all`
        )
        .then((response) => {
          appStore.shimmerLoadingFinish();
          appStore.loadingFinish();

          this.allActivities = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.shimmerLoadingFinish();
          appStore.loadingFinish();

          throw error;
        });
    },

    async addActivity(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/activities/create`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadActivityDetails(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/activities/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.activityDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateActivity(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .put(`/admin/activities/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return response.data.data;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async deleteActivity(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .delete(`/admin/activities/delete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
