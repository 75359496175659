import { permissions } from "@/enums/permissions.enum";

export default [
    {
        path: "financial",
        meta: {
            pageTitle: "sidebar.Clients financial operations",
        },
        children: [
            {
                path: "clients",
                meta: {
                    pageTitle: "financial.Clients financial operations",
                    breadcrumbs: [
                        {
                            title: "sidebar.Financial Operations",
                            link: "financial-clients",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "financial-clients",
                        component: () => import("@/views/financial/clients/Index.vue"),
                        meta: {
                            pageTitle: "financial.Clients financial operations",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Financial Operations",
                                    link: "financial-clients",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readFinancialClients],
                        },
                    },
                    {
                        path: ":id/details",
                        name: "financial-clients-details",
                        component: () => import("@/views/financial/clients/details/Index.vue"),
                        meta: {
                            pageTitle: "financial.Client Financial Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Financial Operations",
                                    link: "financial-clients",
                                    disabled: false,
                                },
                                {
                                    title: "financial.Client Financial Details",
                                    link: "financial-clients-details",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readFinancialClients],
                        },
                    },
                ],

            },
            {
                path: "providers",
                meta: {
                    pageTitle: "financial.Service Providers financial operations",
                    breadcrumbs: [
                        {
                            title: "sidebar.Financial Operations",
                            link: "financial-providers",
                            disabled: true,
                        }
                    ],
                },
                children: [
                    {
                        path: "",
                        name: "financial-providers",
                        component: () => import("@/views/financial/providers/Index.vue"),
                        meta: {
                            pageTitle: "financial.Service Providers financial operations",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Financial Operations",
                                    link: "financial-providers",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readFinancialProviders, permissions.addFinancialTransaction],
                        },
                    },
                    {
                        path: ":id/details",
                        name: "financial-providers-details",
                        component: () => import("@/views/financial/providers/details/Index.vue"),
                        meta: {
                            pageTitle: "financial.Financial Provider Details",
                            breadcrumbs: [
                                {
                                    title: "sidebar.Financial Operations",
                                    link: "financial-providers",
                                    disabled: false,
                                },
                                {
                                    title: "financial.Financial Provider Details",
                                    link: "financial-providers-details",
                                    disabled: true,
                                }
                            ],
                            permissions: [permissions.readFinancialProviders],
                        },
                    },
                ],
            },
        ],
    },
];
