import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useAdvertisementstore = defineStore({
  id: "Advertisements",
  state: () => ({
    advertisements: [],
    totalAdvertisements: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    advertisementDetails: null,
  }),
  getters: {
    getAdvertisements: (state) => state.advertisements,
    getTotalAdvertisements: (state) => state.totalAdvertisements,
    getAdvertisementDetails: (state) => state.advertisementDetails,
  },
  actions: {
    async loadAdvertisements(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/ads?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.advertisements = response.data.data.data;
          this.totalAdvertisements.currentPage = response.data.data.current_page;
          this.totalAdvertisements.totalItems = response.data.data.total;
          this.totalAdvertisements.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async addAdvertisement(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/ads/create`, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadAdvertisementDetails(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/ads/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.advertisementDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateAdvertisement(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/ads/update/${data.id}`, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return response.data.data;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async deleteAdvertisement(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .delete(`/admin/ads/delete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async toggleAdvertisementStatus(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .put(`/admin/ads/toggle/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.change_status_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
