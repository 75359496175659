import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useUtilityStore = defineStore({
  id: "utility",
  state: () => ({
    dashboardStatistics: null,
  }),
  getters: {
    getDashboardStatistics: (state) => state.dashboardStatistics,
  },
  actions: {
    async loadDashboardStatistics() {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/utils/dashboard/statistics`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.dashboardStatistics = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async uploadFile(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/utils/files/upload`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          appStore.loadingFinish();
          return response.data.data;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
