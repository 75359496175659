import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useOrderPackagesStore = defineStore({
  id: "orderPackages",
  state: () => ({
    orderPackages: [],
    totalOrderPackages: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    orderPackageDetails: null,
  }),
  getters: {
    getOrderPackages: (state) => state.orderPackages,
    getTotalOrderPackages: (state) => state.totalOrderPackages,
    getOrderPackageDetails: (state) => state.orderPackageDetails,
  },
  actions: {
    async loadOrderPackages(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/orders/packages`,
          {
            params: data,
          }
        )
        .then((response) => {
          appStore.loadingFinish();
          this.orderPackages = response.data.data.data;
          this.totalOrderPackages.currentPage = response.data.data.current_page;
          this.totalOrderPackages.totalItems = response.data.data.total;
          this.totalOrderPackages.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadOrderPackageDetails(id) {
      this.orderPackageDetails = null;
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/orders/packages/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.orderPackageDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },



    async updateData(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .put(`/admin/orders/packages/data/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.Data updated successfully'),
            type: "success",
          });
          this.orderPackageDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async cancelPackageOrder(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/orders/packages/cancel/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.cancelled_successfully'),
            type: "success",
          });
          this.orderPackageDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async completePackageOrder(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/orders/packages/complete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.cancelled_successfully'),
            type: "success",
          });
          this.orderPackageDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updatePackageOrderFiles(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/orders/packages/files/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.files_update_successfully'),
            type: "success",
          });
          this.orderPackageDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async updatePackageOrderPassports(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/orders/packages/passports/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.files_update_successfully'),
            type: "success",
          });
          this.orderPackageDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async addPaymentAmount(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/orders/packages/payment/add/${data.id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.payment_added_successfully'),
            type: "success",
          });
          this.orderPackageDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    }
  },
});
