import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useCountryStore = defineStore({
  id: "countries",
  state: () => ({
    countries: [],
    totalCountries: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    allCountries: [],
    allCitiesForCountry: [],
    countryDetails: null,
  }),
  getters: {
    getCountries: (state) => state.countries,
    getTotalCountries: (state) => state.totalCountries,
    getAllCountries: (state) => state.allCountries,
    getAllCitiesForCountry: (state) => state.allCitiesForCountry,
    getCountryDetails: (state) => state.countryDetails,
  },
  actions: {
    async loadCountries(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/countries?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.countries = response.data.data.data;
          this.totalCountries.currentPage = response.data.data.current_page;
          this.totalCountries.totalItems = response.data.data.total;
          this.totalCountries.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadAllCountries(fullLoad = false) {
      this.allCitiesForCountry = [];
      const appStore = useAppsStore();
      appStore.shimmerLoadingStart();
      if (fullLoad) {
        appStore.loadingStart();
      }
      return await this.$http
        .get(
          `/admin/countries/all`
        )
        .then((response) => {
          appStore.shimmerLoadingFinish();
          appStore.loadingFinish();
          this.allCountries = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.shimmerLoadingFinish();
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadAllCitiesForCountry(id) {
      const appStore = useAppsStore();
      appStore.shimmerLoadingStart();
      return await this.$http
        .get(
          `/admin/countries/cities/${id}`
        )
        .then((response) => {
          appStore.shimmerLoadingFinish();
          this.allCitiesForCountry = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.shimmerLoadingFinish();
          throw error;
        });
    },

    async addCountry(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/countries/create`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadCountryDetails(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/countries/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.countryDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateCountry(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .put(`/admin/countries/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async toggleCountryStatus(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .put(`/admin/countries/toggle/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.change_status_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },



    async deleteCountry(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .delete(`/admin/countries/delete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
