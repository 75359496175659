import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const usePackageStore = defineStore({
  id: "packages",
  state: () => ({
    packages: [],
    totalPackages: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    allPackages: [],
    packageDetails: null,
  }),
  getters: {
    getPackages: (state) => state.packages,
    getTotalPackages: (state) => state.totalPackages,
    getAllPackages: (state) => state.allPackages,
    getPackageDetails: (state) => state.packageDetails,
  },
  actions: {
    async loadPackages(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/services/packages?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.packages = response.data.data.data;
          this.totalPackages.currentPage = response.data.data.current_page;
          this.totalPackages.totalItems = response.data.data.total;
          this.totalPackages.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadAllPackages() {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/services/packages/all`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.allPackages = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async addPackage(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/services/packages/create`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadPackageDetails(id) {
      this.packageDetails = null;
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/services/packages/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.packageDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updatePackage(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/services/packages/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async deletePackage(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .delete(`/admin/services/packages/delete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async togglePackagesStatus(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/services/packages/toggle/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.change_status_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

  },
});
