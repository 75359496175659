import HomeIcon from "./svg/home.svg";
import NutIcon from "./svg/nut.svg";
import Bell1Icon from "./svg/bell-1.svg";
import Comments21Icon from "./svg/comments-2-1.svg";
import chevronDownIcon from "./svg/chevron-down.svg";
import fileTextIcon from "./svg/file-text.svg";
import logout2 from "./svg/log-out-2.svg";
import comment2dots from "./svg/comment-2-dots.svg";
import globe from "./svg/globe.svg";
import inboxin1 from "./svg/inbox-in-1.svg";
import layers1 from "./svg/layers-1.svg";
import users1 from "./svg/users-1.svg";
import building from "./svg/building.svg?inline";


const svgComponents = (app) => {
  return (
    app.component("home-icon", HomeIcon)
      .component("nut-icon", NutIcon)
      .component("bell-1-icon", Bell1Icon)
      .component("bell-1-icon", Bell1Icon)
      .component("comments-2-1-icon", Comments21Icon)
      .component("chevron-down-icon", chevronDownIcon)
      .component("file-text-icon", fileTextIcon)
      .component("logout-2-icon", logout2)
      .component("comment-2-dots-icon", comment2dots)
      .component("globe-icon", globe)
      .component("inbox-in-1-icon", inboxin1)
      .component("layers-1-icon", layers1)
      .component("users-1-icon", users1)
      .component("building-icon", building)
  );

};

export default svgComponents;
