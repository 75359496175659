import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useProviderStore = defineStore({
  id: "providers",
  state: () => ({
    providers: [],
    totalProviders: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    allProviders: [],
    providerDetails: null,
  }),
  getters: {
    getProviders: (state) => state.providers,
    getTotalProviders: (state) => state.totalProviders,
    getAllProviders: (state) => state.allProviders,
    getProviderDetails: (state) => state.providerDetails,
  },
  actions: {
    async loadProviders(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/providers?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.providers = response.data.data.data;
          this.totalProviders.currentPage = response.data.data.current_page;
          this.totalProviders.totalItems = response.data.data.total;
          this.totalProviders.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadAllProviders(fullLoad = false) {
      const appStore = useAppsStore();
      appStore.shimmerLoadingStart();
      if (fullLoad) {
        appStore.loadingStart();
      }
      return await this.$http
        .get(
          `/admin/providers/all`
        )
        .then((response) => {
          appStore.shimmerLoadingFinish();
          appStore.loadingFinish();

          this.allProviders = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.shimmerLoadingFinish();
          appStore.loadingFinish();
          throw error;
        });
    },

    async addProvider(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/providers/create`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadProviderDetails(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/providers/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.providerDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateProvider(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/providers/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async deleteProvider(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .delete(`/admin/providers/delete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async toggleProviderStatus(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/providers/toggle/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.change_status_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
