import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useInclusionstore = defineStore({
  id: "inclusions",
  state: () => ({
    inclusions: [],
    inclusionDetails: null,
  }),
  getters: {
    getInclusions: (state) => state.inclusions,
    getInclusionDetails: (state) => state.inclusionDetails,
  },
  actions: {
    async loadInclusions(fullLoad = false) {
      const appStore = useAppsStore();
      if (fullLoad) {
        appStore.loadingStart();
      }
      appStore.shimmerLoadingStart();
      return await this.$http
        .get(
          `/admin/inclusions`
        )
        .then((response) => {
          appStore.shimmerLoadingFinish();
          appStore.loadingFinish();
          this.inclusions = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.shimmerLoadingFinish();
          appStore.loadingFinish();
          throw error;
        });
    },


    async updateInsurancesData(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/inclusions/update`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          this.inclusions = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
