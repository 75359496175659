import { h, resolveComponent } from "vue";
import middleware from "../middleware/middleware";
import { permissions } from "@/enums/permissions.enum";
import orders from "./orders";
import packages from "./packages";
import users from "./users";
import ads from "./ads";
import chats from "./chats";
import financial from "./financial";
import settings from "./settings";

export default [
    {
        path: "",
        redirect: "dashboard",
        component: () => import("@/layouts/default-layout/DefaultLayout.vue"),
        meta: {
            middleware: [middleware],
        },
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: () => import("@/views/Dashboard.vue"),
                meta: {
                    pageTitle: "sidebar.Home",
                    breadcrumbs: [
                        {
                            title: "sidebar.Home",
                            link: "dashboard",
                            disabled: true,
                        }
                    ],
                    permissions: [],
                },
            },
            {
                path: "notifications",
                name: "notifications",
                component: () => import("@/views/Notifications.vue"),
                meta: {
                    pageTitle: "sidebar.Home",
                    breadcrumbs: [
                        {
                            title: "common.Notifications",
                            link: "notifications",
                            disabled: true,
                        }
                    ],
                    permissions: [],
                },
            },
            {
                path: "profile",
                name: "profile",
                component: () => import("@/views/Profile.vue"),
                meta: {
                    pageTitle: "sidebar.Account data",
                    breadcrumbs: [
                        {
                            title: "sidebar.Account data",
                            link: "profile",
                            disabled: true,
                        }
                    ],
                    permissions: [],
                },
            },
            ...orders,
            ...packages,
            ...users,
            ...ads,
            ...chats,
            ...financial,
            ...settings,
        ],
    },
    {
        path: "",
        component: () => import("@/layouts/AuthLayout.vue"),
        meta: {
            middleware: [middleware],
        },
        children: [
            {
                path: "login",
                name: "login",
                component: () =>
                    import("@/views/authentication/Login.vue"),
                meta: {
                    pageTitle: "auth.Login",
                },
            },
            {
                path: "password-reset",
                name: "password-reset",
                component: () =>
                    import("@/views/authentication/PasswordReset.vue"),
                meta: {
                    pageTitle: "auth.Password Recovery",
                },
            },
            {
                path: "code-confirmation",
                name: "code-confirmation",
                component: () =>
                    import("@/views/authentication/CodeConfirmation.vue"),
                meta: {
                    pageTitle: "auth.Send code",
                },
            },
            {
                path: "new-password",
                name: "new-password",
                component: () =>
                    import("@/views/authentication/NewPassword.vue"),
                meta: {
                    pageTitle: "auth.New password",
                },
            },
        ],
    },
    {
        path: "",
        component: () => import("@/layouts/SystemLayout.vue"),
        meta: {
            middleware: [middleware],
        },
        children: [
            {
                // the 404 route, when none of the above matches
                path: "404",
                name: "404",
                component: () => import("@/views/authentication/Error404.vue"),
                meta: {
                    pageTitle: "Error 404",
                },
            },
            {
                path: "500",
                name: "500",
                component: () => import("@/views/authentication/Error500.vue"),
                meta: {
                    pageTitle: "Error 500",
                },
            },
        ],
    },
];
