import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useSettingStore = defineStore({
  id: "settings",
  state: () => ({
    settingDetails: null,
  }),
  getters: {
    getSettingDetails: (state) => state.settingDetails,
  },
  actions: {
    async loadSettingDetails() {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/settings`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.settingDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateSetting(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/settings/update`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.settingDetails = response.data.data;
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async updatePrivacyPolicy(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/settings/privacy-policy/update`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.settingDetails = response.data.data;
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateTermsAndConditions(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/settings/terms-conditions/update`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.settingDetails = response.data.data;
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

  },
});
