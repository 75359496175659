import { defineStore } from "pinia";
import { useCookies } from "vue3-cookies";
import { useAppsStore } from "@/stores/app";

import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getFirestore,
  increment,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  setDoc,
  where,
} from "firebase/firestore";
import {
  userTypes
} from "@/enums/userType.enum";

import moment from 'moment'
import {
  MessageTypeEnum
} from "@/enums/messageType.enum";

const { cookies } = useCookies();

const collectionName = import.meta.env.VITE_APP_CONVERSATION_COLLECTION;
const unreadMessageCountcollectionName = import.meta.env.VITE_APP_UNREAD_MESSAGE_COUNT_CONVERSATION_COLLECTION;


function getRoomInfo(members, wantedSection) {
  if (members) {
    let room = members.find((member) => {
      return member.type == userTypes.admin;
    });

    if (room) {
      if (wantedSection === 'name') {
        if (room.name) {
          return room.name
        } else {
          return 'unknown user'
        }
      }
      if (wantedSection === 'avatar') {
        if (room.avatar) {
          return room.avatar
        } else {
          return 'unknown user'
        }
      }
    }
  }
  return "";
}

function getUserInfo(members, userId, wantedSection) {
  if (members) {
    let room = members.find((member) => {
      return member.id == userId;
    });

    if (room) {
      if (wantedSection === 'name') {
        if (room.name) {
          return room.name
        } else {
          return 'unknown user'
        }
      }
      if (wantedSection === 'avatar') {
        if (room.avatar) {
          return room.avatar
        } else {
          return 'unknown user'
        }
      }
    }
  }

  return "";
}

function arabicToEnglish(s) {
  return s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
}


export const useChatStore = defineStore({
  id: "chat",
  state: () => ({
    subscribe: null,
    unreadMessagesCount: 0,
    roomIds: [],
    rooms: [],
    roomMessages: [],
    chatRoomOrder: null,
  }),
  getters: {
    getSubscribe: (state) => state.subscribe,
    getUnreadMessagesCount: (state) => state.unreadMessagesCount,
    getRoomIds: (state) => state.roomIds,
    getRooms: (state) => state.rooms,
    getRoomMessages: (state) => state.roomMessages,
    getChatRoomOrder: (state) => state.chatRoomOrder,
  },
  actions: {
    async loadChatRoomOrder(id) {
      return await this.$http
        .get(`/admin/orders/chat/room/order/${id}`)
        .then((response) => {
          this.chatRoomOrder = response.data.data;
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },

    async updateChatRoom(data) {
      return await this.$http
        .post(`/admin/orders/chat/room/update`, data)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },

    async loadRooms(currentUserId) {
      this.roomMessages = [];
      const db = getFirestore();
      const conversationsRef = query(
        collection(db, collectionName),
        where('isConversationWithAdmin', '==', true),
        where('membersType', 'array-contains', userTypes.admin)
        // where('membersId', 'array-contains', currentUserId.toString())
      );

      // establish a listener for the query using querySnapshot.
      onSnapshot(conversationsRef, querySnapshot => {
        let roomsIds = [];
        let rooms = [];

        querySnapshot.forEach(doc => {

          let docData = doc.data();

          roomsIds.push(docData.id);

          rooms.push({
            roomId: docData.id,
            index: new Date(arabicToEnglish(docData.lastMessageDate)),
            roomName: getRoomInfo(docData.members, 'name'),
            orderId: docData.orderId,
            orderNum: docData.orderNum,
            serviceType: docData.serviceType,
            avatar: getRoomInfo(docData.members, 'avatar'),
            unreadCount: docData.unreadMessagesMemberA,
            startingById: docData.startingById,
            closedById: docData.closedById,
            users: docData.members ? docData.members.map((d) => {
              return {
                _id: d.id,
                avatar: d.avatar,
                username: d.name
              };
            }) : [],
            lastMessage: {
              content: docData.lastMessage,
              timestamp: new Intl.DateTimeFormat('default', {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(arabicToEnglish(docData.lastMessageDate))),
            },
          });
        })
        this.roomIds = roomsIds;
        this.rooms = rooms;
        
      });

    },

    async startConversation(data) {
      moment.locale('en');

      const db = await getFirestore();
      const roomRef = doc(db, collectionName, data.id);
      await setDoc(roomRef, {
        id: data.id,
        members: data.members,
        membersId: data.membersId,
        membersType: data.membersType,
        orderId: data.orderId,
        orderNum: data.orderNum,
        serviceType: data.serviceType,
        lastMessage: "",
        lastMessageDate: moment().format(),
        unreadMessagesMemberA: 0,
        unreadMessagesMemberB: 0,
        isConversationWithAdmin: true,
        startingById: null,
        closedById: null,
      });

      await addDoc(collection(db, collectionName, data.id, 'messages'), {
        body: "",
        created: moment().format(),
        senderId: null,
        type: "",
      });
    },

    // load admin countUnreadMessages from the collection unreadMessageCount
    async loadUnreadMessagesCount() {
      const db = await getFirestore();
      const unreadMessageCountDoc = doc(db, unreadMessageCountcollectionName, 'admin');
      const snapshot = await getDoc(unreadMessageCountDoc);

      await onSnapshot(unreadMessageCountDoc, async snapshot => {
        if (snapshot.exists()) {
          this.unreadMessagesCount = snapshot.data().countUnreadMessages;
        }

      });

      if (snapshot.exists()) {
        this.unreadMessagesCount = snapshot.data().countUnreadMessages;
      }

    },


    async sendMessage(data) {
      moment.locale('en');

      const db = await getFirestore();
      const roomDoc = doc(db, collectionName, data.roomId);

      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(roomDoc);
        if (!sfDoc.exists()) {
          throw 'Document does not exist!';
        }

        const startAdminId = sfDoc.data().startingById;
        const closedById = sfDoc.data().closedById;
        const membersId = sfDoc.data().membersId;
        const membersType = sfDoc.data().membersType;
        const user = sfDoc.data().members.find((member) => {
          return member.type != 1;//userTypes.admin;
        });


        const unreadMessageCountDoc = doc(db, unreadMessageCountcollectionName, user.id);
        const adminUnreadMessageCountDoc = doc(db, unreadMessageCountcollectionName, 'admin');


        if (startAdminId == null) {
          let updateData = {
            startingById: data.user.id,
            orderId: data.orderId,
            orderNum: data.orderNum,
            serviceType: data.serviceType,
            sender: data.sender,
            members: arrayUnion(data.user),
            membersId: arrayUnion(data.user.id),
            membersType: arrayUnion(data.user.type),
            lastMessage: data.message.content,
            lastMessageDate: moment().format(),
            unreadMessagesMember: increment(1),
          };

          if (data.message.type !== MessageTypeEnum.text) {
            updateData['lastMessage'] = data.message.type;
          }

          transaction.update(roomDoc, updateData);


          await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
            body: data.message.content,
            created_at: moment().format(),
            sender: data.sender,
            sender_id: data.user.id,
            type: data.message.type,
            is_read: false
          });  // unreadMessages for user


          // if unreadMessageCountDoc is not exist create it
          const unreadMessageCountDocSnapshot = await getDoc(unreadMessageCountDoc);
          if (!unreadMessageCountDocSnapshot.exists()) {
            await setDoc(unreadMessageCountDoc, {
              countUnreadMessages: increment(1)
            });
          } else {
            if (unreadMessageCountDocSnapshot.data().countUnreadMessages < 0) {
              await transaction.update(unreadMessageCountDoc, {
                countUnreadMessages: increment(1)
              });
            }
          }

          //decrease countUnreadMessages for admin by the current room unreadMessagesMemberA
          await transaction.update(adminUnreadMessageCountDoc, {
            countUnreadMessages: increment(-sfDoc.data().unreadMessagesMemberA)
          });

          //check if the countUnreadMessages is less than 0
          const adminUnreadMessageCountDocSnapshot = await getDoc(adminUnreadMessageCountDoc);
          // if the doc is not exist create it
          if (!adminUnreadMessageCountDocSnapshot.exists()) {
            await setDoc(adminUnreadMessageCountDoc, {
              countUnreadMessages: 0
            });
          } else {
            if (adminUnreadMessageCountDocSnapshot.data().countUnreadMessages < 0) {
              await transaction.update(adminUnreadMessageCountDoc, {
                countUnreadMessages: 0
              });
            }
          }

          //set unreadMessagesMemberA to 0
          await transaction.update(roomDoc, {
            unreadMessagesMemberA: 0
          });

          return startAdminId;
        } else {
          if (membersId.includes(data.user.id) || membersType.includes(data.user.type)) {
            let updateData = {
              unreadMessagesMemberB: increment(1),
              lastMessage: data.message.content,
              lastMessageDate: moment().format()
            };

            if (data.message.type !== MessageTypeEnum.text) {
              updateData['lastMessage'] = data.message.type;
            }

            transaction.update(roomDoc, updateData);

            await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
              body: data.message.content,
              created_at: moment().format(),
              sender: data.sender,
              sender_id: data.user.id,
              type: data.message.type,
              is_read: false
            });

            //increase countUnreadMessages for user
            await transaction.update(unreadMessageCountDoc, {
              countUnreadMessages: increment(1)
            });


            //decrease countUnreadMessages for admin by the current room unreadMessagesMemberA
            await transaction.update(adminUnreadMessageCountDoc, {
              countUnreadMessages: increment(-sfDoc.data().unreadMessagesMemberA)
            });

            //check if the countUnreadMessages is less than 0
            const adminUnreadMessageCountDocSnapshot = await getDoc(adminUnreadMessageCountDoc);
            // if the doc is not exist create it
            if (!adminUnreadMessageCountDocSnapshot.exists()) {
              await setDoc(adminUnreadMessageCountDoc, {
                countUnreadMessages: 0
              });
            } else {
              if (adminUnreadMessageCountDocSnapshot.data().countUnreadMessages < 0) {
                await transaction.update(adminUnreadMessageCountDoc, {
                  countUnreadMessages: 0
                });
              }
            }


            //set unreadMessagesMemberA to 0
            await transaction.update(roomDoc, {
              unreadMessagesMemberA: 0
            });
            return true;
          }

          return false;
        }
      });


    },


    async loadRoomMessages(roomId, userId) {
      this.roomMessages = [];
      const db = await getFirestore();
      const conversations = doc(db, collectionName, roomId);
      const snapshot = await getDoc(conversations);
      const members = snapshot.data().members;

      // save the current room id  to use it in the chat component to send the message to the correct room id     
      if (this.subscribe) {
        this.subscribe();
      }



      if (snapshot.exists()) {
        const messageRef = query(
          collection(db, collectionName, roomId, 'messages'),
          orderBy('created_at', 'asc'),
        );
        // establish a listener for the query using querySnapshot.
        let subscribe = onSnapshot(messageRef, async (querySnapshot) => {

          let messages = [];
          querySnapshot.forEach(doc => {
            const msg = doc.data();

            const message = {
              _id: doc.id,
              content: msg.body,
              type: msg.type,
              sender: msg.sender,
              senderId: msg.sender_id,
              username: getUserInfo(members, msg.sender_id, 'name'),
              avatar: getUserInfo(members, msg.sender_id, 'avatar'),
              timestamp: new Intl.DateTimeFormat('default', {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(arabicToEnglish(msg.created_at))),
              is_read: msg.is_read,
              date: new Date(arabicToEnglish(msg.created_at)).toDateString(),
            };

            messages.push(message);
          });

          this.roomMessages = messages;

          querySnapshot.forEach(doc => {
            const msg = doc.data();
            if (msg.sender_id != userId && !msg.is_read) {
              const messageRef = doc.ref;
              runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(messageRef);
                if (!sfDoc.exists) {
                  throw 'Document does not exist!';
                }
                transaction.update(messageRef, {
                  is_read: true
                });
              });
            }
          });

          //decrease countUnreadMessages for admin by the current room unreadMessagesMemberA
          const adminUnreadMessageCountDoc = doc(db, unreadMessageCountcollectionName, 'admin');
          await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(adminUnreadMessageCountDoc);
            if (!sfDoc.exists()) {
              throw new Error('Document does not exist!');
            }
            transaction.update(adminUnreadMessageCountDoc, {
              countUnreadMessages: increment(-snapshot.data().unreadMessagesMemberA)
            });
          });

          //check if the countUnreadMessages is less than 0
          const adminUnreadMessageCountDocSnapshot = await getDoc(adminUnreadMessageCountDoc);
          // if the doc is not exist create it
          if (!adminUnreadMessageCountDocSnapshot.exists()) {
            await setDoc(adminUnreadMessageCountDoc, {
              countUnreadMessages: 0
            });
          } else {
            if (adminUnreadMessageCountDocSnapshot.data().countUnreadMessages < 0) {
              await runTransaction(db, async (transaction) => {
                transaction.update(adminUnreadMessageCountDoc, {
                  countUnreadMessages: 0
                });
              });
            }
          }

          //set unreadMessagesMemberA to 0
          runTransaction(db, async (transaction) => {
            transaction.update(conversations, {
              unreadMessagesMemberA: 0
            });
          });



        });

        this.subscribe = subscribe;
      }
    },

    async unSubscribeRoom() {
      if (this.subscribe) {
        this.subscribe();
      }
    }
  },
});


