import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useSystemUserstore = defineStore({
  id: "systemUsers",
  state: () => ({
    systemUsers: [],
    totalSystemUsers: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    allRoles: [],
    systemUserDetails: null,
  }),
  getters: {
    getSystemUsers: (state) => state.systemUsers,
    getTotalSystemUsers: (state) => state.totalSystemUsers,
    getAllRoles: (state) => state.allRoles,
    getSystemUserDetails: (state) => state.systemUserDetails,
  },
  actions: {
    async loadSystemUsers(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/system-users?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.systemUsers = response.data.data.data;
          this.totalSystemUsers.currentPage = response.data.data.current_page;
          this.totalSystemUsers.totalItems = response.data.data.total;
          this.totalSystemUsers.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadAllRoles() {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(`/admin/system-users/roles`)
        .then((response) => {
          appStore.loadingFinish();
          this.allRoles = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async addSystemUser(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/system-users/create`, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadSystemUserDetails(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/system-users/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.systemUserDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateSystemUser(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/system-users/update/${data.id}`, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return response.data.data;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async deleteSystemUser(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .delete(`/admin/system-users/delete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async toggleSystemUserStatus(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .put(`/admin/system-users/toggle/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.change_status_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
