import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useOrderInsurancesStore = defineStore({
  id: "orderInsurances",
  state: () => ({
    orderInsurances: [],
    totalOrderInsurances: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    orderInsuranceDetails: null,
  }),
  getters: {
    getOrderInsurances: (state) => state.orderInsurances,
    getTotalOrderInsurances: (state) => state.totalOrderInsurances,
    getOrderInsuranceDetails: (state) => state.orderInsuranceDetails,
  },
  actions: {
    async loadOrderInsurances(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/orders/insurances`,
          {
            params: data,
          }
        )
        .then((response) => {
          appStore.loadingFinish();
          this.orderInsurances = response.data.data.data;
          this.totalOrderInsurances.currentPage = response.data.data.current_page;
          this.totalOrderInsurances.totalItems = response.data.data.total;
          this.totalOrderInsurances.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadOrderInsuranceDetails(id) {
      this.orderInsuranceDetails = null;
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/orders/insurances/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.orderInsuranceDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },



    async updatePrice(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .put(`/admin/orders/insurances/price/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.Price updated successfully'),
            type: "success",
          });
          this.orderInsuranceDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async cancelInsuranceOrder(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/orders/insurances/cancel/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.cancelled_successfully'),
            type: "success",
          });
          this.orderInsuranceDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateInsuranceOrderFiles(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/orders/insurances/files/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.files_update_successfully'),
            type: "success",
          });
          this.orderInsuranceDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async addPaymentAmount(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/orders/insurances/payment/add/${data.id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.payment_added_successfully'),
            type: "success",
          });
          this.orderInsuranceDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    }
  },
});
