import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useNotificationstore = defineStore({
  id: "notifications",
  state: () => ({
    notifications: [],
    totalNotifications: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    notificationDetails: null,
    dashboardNotifications: [],
    unreadNotifications: 0,
    totalDashboardNotifications: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  }),
  getters: {
    getNotifications: (state) => state.notifications,
    getTotalNotifications: (state) => state.totalNotifications,
    getNotificationDetails: (state) => state.notificationDetails,
    getDashboardNotifications: (state) => state.dashboardNotifications,
    getUnreadNotifications: (state) => state.unreadNotifications,
    getTotalDashboardNotifications: (state) => state.totalDashboardNotifications,
  },
  actions: {

    async loadDashboardNotifications(data, loadingStart = false) {
      const appStore = useAppsStore();
      if (loadingStart) {
        appStore.loadingStart();
      }
      return await this.$http
        .get(
          `/admin/notifications/dashboard?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          if (loadingStart) {
            appStore.loadingFinish();
          }
          this.dashboardNotifications = response.data.data.list.data;
          this.unreadNotifications = response.data.data.unread;
          this.totalDashboardNotifications.currentPage = response.data.data.list.current_page;
          this.totalDashboardNotifications.totalItems = response.data.data.list.total;
          this.totalDashboardNotifications.totalPages = response.data.data.list.last_page;
          return true;
        })
        .catch((error) => {
          if (loadingStart) {
            appStore.loadingFinish();
          }
          throw error;
        });
    },

    // markAsRead 
    async markAsRead(data) {
      return await this.$http
        .post(
          `/admin/notifications/dashboard/read`, data
        )
        .then((response) => {
          this.unreadNotifications = this.unreadNotifications - data.ids.length;
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },

    async loadNotifications(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/notifications?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.notifications = response.data.data.data;
          this.totalNotifications.currentPage = response.data.data.current_page;
          this.totalNotifications.totalItems = response.data.data.total;
          this.totalNotifications.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async addNotification(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/notifications/create`, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadNotificationDetails(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/notifications/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.notificationDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateNotification(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/notifications/update/${data.id}`, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return response.data.data;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async deleteNotification(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .delete(`/admin/notifications/delete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
