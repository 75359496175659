import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useSystemRolestore = defineStore({
  id: "systemRoles",
  state: () => ({
    systemRoles: [],
    totalSystemRoles: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    allPermissions: [],
    systemRoleDetails: null,
  }),
  getters: {
    getSystemRoles: (state) => state.systemRoles,
    getTotalSystemRoles: (state) => state.totalSystemRoles,
    getAllPermissions: (state) => state.allPermissions,
    getSystemRoleDetails: (state) => state.systemRoleDetails,
  },
  actions: {
    async loadSystemRoles(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/system-roles?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.systemRoles = response.data.data.data;
          this.totalSystemRoles.currentPage = response.data.data.current_page;
          this.totalSystemRoles.totalItems = response.data.data.total;
          this.totalSystemRoles.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadAllPermissions() {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(`/admin/system-roles/permissions`)
        .then((response) => {
          appStore.loadingFinish();
          this.allPermissions = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async addSystemRole(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/system-roles/create`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadSystemRoleDetails(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/system-roles/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.systemRoleDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateSystemRole(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .put(`/admin/system-roles/update/${data.id}`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return response.data.data;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async deleteSystemRole(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .delete(`/admin/system-roles/delete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async toggleSystemRoleStatus(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .put(`/admin/system-roles/toggle/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.change_status_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
