// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeAuth } from "firebase/auth";
import axios from "axios";
import { useCookies } from "vue3-cookies";


const { cookies } = useCookies();
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/**
 * Firebase configuration object.
 *
 * @typedef {Object} FirebaseConfig
 * @property {string} apiKey - The API key for Firebase.
 * @property {string} authDomain - The domain for Firebase authentication.
 * @property {string} projectId - The project ID for Firebase.
 * @property {string} storageBucket - The storage bucket for Firebase.
 * @property {string} messagingSenderId - The messaging sender ID for Firebase.
 * @property {string} appId - The app ID for Firebase.
 * @property {string} measurementId - The measurement ID for Firebase.
 */

/**
 * Firebase configuration.
 *
 * @type {FirebaseConfig}
 */


const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIRE_BASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIRE_BASE_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIRE_BASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIRE_BASE_APP_ID,
  measurementId: import.meta.env.VITE_APP_FIRE_BASE_MEASUREMENT_ID,
};
const loginData = cookies.get("safarfy-login-data", {
  parseJSON: true,
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAuth(app);

const messaging = getMessaging(app);

if (loginData) {
  getToken(messaging, { vapidKey: import.meta.env.VITE_APP_FIRE_BASE_VAPID_KEY })
    .then((currentToken) => {
      if (Notification.permission === "granted") {
        if (currentToken) {
          // axios.post(
          //   import.meta.env.VITE_APP_API_URL +
          //   "/web/user/notifications/fcm/register",
          //   {
          //     platform: "web",
          //     token: currentToken,
          //   }
          // );
        }
      } else {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            if (currentToken) {
              // axios.post(
              //   import.meta.env.VITE_APP_API_URL +
              //   "/web/user/notifications/fcm/register",
              //   {
              //     platform: "web",
              //     token: currentToken,
              //   }
              // );
            }
          }
        });
      }

      if (currentToken) {
        // Subscribe to topic
        subscribeTokenToTopic(currentToken, 'admin');
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });

  onMessage(messaging, function (payload) {
    if (Notification.permission === "granted") {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("newNotification", {
            detail: {
              storage: payload,
            },
          })
        );
      }, 1000);
    } else {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          setTimeout(() => {
            window.dispatchEvent(
              new CustomEvent("newNotification", {
                detail: {
                  storage: payload,
                },
              })
            );
          }, 1000);
        }
      });
    }
  });





}

function subscribeTokenToTopic(token, topic) {
  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `key=${import.meta.env.VITE_APP_FIRE_BASE_SERVER_KEY}`
    })
  })
    .then((response) => {
      console.log(`topic is subscribed`);
    })
    .catch((error) => {
      console.error(error.result);
    });
  return true;
}


function unsubscribeTokenFromTopic(token, topic) {
  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `key=${import.meta.env.VITE_APP_FIRE_BASE_SERVER_KEY}`
    })
  })
    .then((response) => {
      console.log(`topic is unsubscribed`);
    })
    .catch((error) => {
      console.error(error.result);
    });
  return true;
}