import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useFinancialClientStore = defineStore({
  id: "financialClient",
  state: () => ({
    financialClients: [],
    totalFinancialClients: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    financialClientsStatsistics: [],
    financialClient: null,
    financialClientsOrders: [],
    totalFinancialClientsOrders: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  }),
  getters: {
    getFinancialClients: (state) => state.financialClients,
    getTotalFinancialClients: (state) => state.totalFinancialClients,
    getFinancialClientsStatistics: (state) => state.financialClientsStatsistics,
    getFinancialClient: (state) => state.financialClient,
    getFinancialClientsOrders: (state) => state.financialClientsOrders,
    getTotalFinancialClientsOrders: (state) => state.totalFinancialClientsOrders,
  },
  actions: {
    async loadFinancialClients(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/financials/clients`,
          {
            params: data,
          }
        )
        .then((response) => {
          appStore.loadingFinish();
          this.financialClients = response.data.data.list.data;
          this.totalFinancialClients.currentPage = response.data.data.list.current_page;
          this.totalFinancialClients.totalItems = response.data.data.list.total;
          this.totalFinancialClients.totalPages = response.data.data.list.last_page;
          this.financialClientsStatsistics = response.data.data.statistics;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadFinancialClientDetails(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/financials/clients/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.financialClient = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadFinancialClientOrders(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/financials/clients/detail/${data.id}/orders`,
          {
            params: data,
          }
        )
        .then((response) => {
          appStore.loadingFinish();
          this.financialClientsOrders = response.data.data.data;
          this.totalFinancialClientsOrders.currentPage = response.data.data.current_page;
          this.totalFinancialClientsOrders.totalItems = response.data.data.total;
          this.totalFinancialClientsOrders.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
