import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
export const useAccountStore = defineStore({
  id: "account",
  state: () => ({
    accountData: null,
    accountPermissions: [],
  }),

  getters: {
    getAccountData: (state) => state.accountData,
    getAccountPermissions: (state) => state.accountPermissions,
  },

  actions: {
    async loadAccountData() {
      return await this.$http.get(`/admin/profile`)
        .then((response) => {
          this.accountData = response.data.data;
          this.accountPermissions = response.data.data.permissions;
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },

    async updateAccountData(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http.post(`/admin/profile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      
      })
        .then((response) => {
          this.accountData = response.data.data;
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async changePassword(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http.post(`/admin/profile/change-password`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.change_password_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async logout() {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http.post(`/admin/profile/logout`)
        .then((response) => {
          appStore.loadingFinish();
          cookies.remove("safarfy-login-data");
          cookies.remove("safarfy-login-data", "/");
          cookies.remove("safarfy-login-data", "/ar");
          cookies.remove("safarfy-login-data", "/en");
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


  },
});
