import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useHomestore = defineStore({
  id: "home",
  state: () => ({
    logs: [],
    totalLogs: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },

    clients: [],
    totalClients: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  }),
  getters: {
    getLogs: (state) => state.logs,
    getTotalLogs: (state) => state.totalLogs,
    getClients: (state) => state.clients,
    getTotalClients: (state) => state.totalClients,
  },
  actions: {

    async uploadFileToServer(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/files/upload`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          appStore.loadingFinish();
          return response.data.data;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadClients(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/clients?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.clients = response.data.data.data;
          this.totalClients.currentPage = response.data.data.current_page;
          this.totalClients.totalItems = response.data.data.total;
          this.totalClients.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadLogs(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/activity-logger?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.logs = response.data.data.data;
          this.totalLogs.currentPage = response.data.data.current_page;
          this.totalLogs.totalItems = response.data.data.total;
          this.totalLogs.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

  },
});
