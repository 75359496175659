import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const useHotelstore = defineStore({
  id: "hotels",
  state: () => ({
    hotels: [],
    totalHotels: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    allHotels: [],
    allRoomsForHotel: [],
    hotelDetails: null,
  }),
  getters: {
    getHotels: (state) => state.hotels,
    getTotalHotels: (state) => state.totalHotels,
    getAllHotels: (state) => state.allHotels,
    getAllRoomsForHotel: (state) => state.allRoomsForHotel,
    getHotelDetails: (state) => state.hotelDetails,
  },
  actions: {
    async loadHotels(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/hotels?page=${data.page}&per_page=${data.per_page}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.hotels = response.data.data.data;
          this.totalHotels.currentPage = response.data.data.current_page;
          this.totalHotels.totalItems = response.data.data.total;
          this.totalHotels.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadAllHotels(countryId, dischargeRooms = true) {
      // if (dischargeRooms) {
      //   this.allRoomsForHotel = [];
      // }

      const appStore = useAppsStore();// 
      appStore.shimmerLoadingStart();
      return await this.$http
        .get(
          `/admin/hotels/all?country_id=${countryId}`
        )
        .then((response) => {
          appStore.shimmerLoadingFinish();
          this.allHotels = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.shimmerLoadingFinish();
          throw error;
        });
    },



    async dischargeAllRoomsForHotel() {
      this.allRoomsForHotel = [];
    },

    async loadAllRoomsForHotel(id) {
      const appStore = useAppsStore();
      appStore.shimmerLoadingStart();
      return await this.$http
        .get(
          `/admin/hotels/rooms/${id}`
        )
        .then((response) => {
          appStore.shimmerLoadingFinish();
          this.allRoomsForHotel = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.shimmerLoadingFinish();
          throw error;
        });
    },


    async addHotel(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/hotels/create`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async loadHotelDetails(id) {
      this.hotelDetails = null;
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/admin/hotels/detail/${id}`
        )
        .then((response) => {
          appStore.loadingFinish();
          this.hotelDetails = response.data.data;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    async updateHotel(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/admin/hotels/update/${data.id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async deleteHotel(id) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .delete(`/admin/hotels/delete/${id}`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
