// import { useI18n } from "vue-i18n";
// const { t, te } = useI18n();

const userTypes = {
    admin: 1,
    user: 2,
    provider: 3,
}

// const accountType = [
//     {
//         id: 1,
//         name: this.$t('account_type.personal'),
//     },
//     {
//         id: 2,
//         name: this.$t('account_type.company'),
//     },

// ];

// function filterAccountType(id) {
//     return accountType.find((item) => item.id === id);
// }

// export { userTypes, accountType, filterAccountType };
export { userTypes};
